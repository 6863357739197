import Vue from 'vue'
import Vuex from 'vuex'
import NodeRSA from 'node-rsa'
import Axios from 'axios';

Vue.use(Vuex)

// Vue.prototype.$nodeRSA=NodeRSA;
//Vuex是一个状态管理器而非一个存储工具，为什么会把token存入Vuex中呢，在Vuex中封装的localStorage操作，可以直接使用localStorage操作数据，但无法监听数据改变。而Vuex是全局存储同时可监听数据状态的变更，当Vuex数值发生变化时可以响应式地监听到该数据的变化。

const tokenModule = {
    state: {token: '', status: false},
    mutations: {
        //储存token方法
        //设置token等于外部传进来的值
        set_token(state, tokenDetail) {
            state.token = tokenDetail.token;
            state.status = tokenDetail.status;
            sessionStorage.token = tokenDetail.token;
        },
        del_token(state) {
            state.token = '';
            state.status = false;
            sessionStorage.removeItem('ip');
            sessionStorage.removeItem('lat');
            sessionStorage.removeItem('lng');
            sessionStorage.removeItem('token');
            Axios.get('/auth/logout');
        }
    },

};

const requestCancelModule = {
    state: {cancelTokenArr: []},
    mutations: {
        PUSH_CANCEL(state, payload) {
            state.cancelTokenArr.push(payload.cancelToken);
        },
        CLAER_CANCEL(state) {
            state.cancelTokenArr.forEach(item => {
                // item('路由跳转取消请求');
            });
            state.cancelTokenArr = [];
        }
    }
};


const systemImageModule = {
    state: {images: {}},
    mutations: {
        set_systemImages(state, systemImage) {
            state.images = systemImage;
        },
    },
    actions: {
        getSystemImage({commit, state}) {
            return new Promise((resolve, reject) => {
                Axios.get('/station/cms/findAllCms').then(res => {
                    if (res.data.success) {
                        if (res.data.data) {
                            commit('set_systemImages', res.data.data);
                            resolve(1)
                        }
                    }
                })
            })
        }
    }
};

const userModule = {
    state: {avatar: '', nickname: '', premium: {expired: false}},
    mutations: {
        set_user(state, user) {
            state.avatar = user.avatarPic;
            state.nickname = user.nickname ? user.nickname : '';
            state.premium = user.premium;
        },
        del_user(state) {
            state.userpic = '';
            state.nickname = '';
            state.premium = {expired: false};
        }
    },
    actions: {
        getUserInfo({commit, state}) {
            Axios.get('/user/ucenter/getUserInfo').then(res => {
                if (res.data.success) {
                    if (res.data.data) {
                        commit('set_user', res.data.data)
                    }
                }
            })
        }
    }
};

const unreadModule = {
    state: {unreadNumber: 0, managerUnreadNumber: {}},
    mutations: {
        set_unreadNumber(state, n) {
            state.unreadNumber = n;
        },
        set_managerUnreadNumber(state, m) {
            state.managerUnreadNumber = m;
        },
    },
    actions: {
        getUnreadNumber({commit, state}) {
            return new Promise((resolve, reject) => {
                Axios.get('/station/moduleMessage/isUnread').then(response => {
                    if (response.data.success) {
                        commit('set_unreadNumber', response.data.data);
                        resolve(response.data.data);
                    }
                })
            })
        },

    }
};


const websocketModule = {
    state: {
        message: {},
        websocket: null,
    },
    mutations: {
        initWebSocket(state, url) {
            state.websocket = new WebSocket(url);
            state.websocket.onmessage = function websocketonmessage(e) {
                state.message = JSON.parse(e.data)
            }
            state.websocket.onclose = function websocketclose(e) {
                // console.warn("closed");
            }
        },
        closeWebSocket(state) {
            if (state.websocket != null) {
                state.websocket.close()
            }
        },
        websocketsend(state, msg) {
            try {
                state.websocket.send(msg);
            } catch (e) {
                // console.warn("send error")
            }
        },
    },
    actions: {
        initWebSocket: function (context, url) {
            context.commit('initWebSocket', url);
        },
        closeWebSocket: function (context) {
            context.commit('closeWebSocket');
        },
        sendMessage: function (context, message) {
            context.commit('websocketsend', message);
        }
    },
}

// 调用 this.sendMessage(message) 发送消息
Vue.prototype.sendMessage = function (message) {
    this.$store.dispatch('sendMessage', message);
};

const keysModule = {
    state: {publicKey: '', privateKey: '', serverKey: ''},
    mutations: {
        set_key(state, pkcs) {
            let pkcsType = pkcs.pkcsType ? pkcs.pkcsType : 'pkcs8';//不为空则 设置为传入参数，为空则 设置为 pkcs8
            let pkcsSize = pkcs.pkcsSize || 1024;
            //1.创建RSA对象，并指定 秘钥长度
            var key = new NodeRSA({b: pkcsSize});
            key.setOptions({encryptionScheme: 'pkcs1'});//指定加密格式
            //2.生成 公钥私钥，使用 pkcs8标准，pem格式
            let publicDer = key.exportKey(pkcsType + '-public-pem');//制定输出格式
            publicDer = publicDer.replace(/\n/g, '');
            publicDer = publicDer.replace('-----BEGIN PUBLIC KEY-----', '');
            publicDer = publicDer.replace('-----END PUBLIC KEY-----', '');
            state.publicKey = publicDer;
            state.privateKey = key.exportKey(pkcsType + '-private-pem');
        },
        set_serverKey(state, serverKey) {
            state.serverKey = serverKey;
            sessionStorage.serverKey = serverKey;
        },
        del_key(state) {
            state.publicKey = '';
            state.privateKey = '';
            state.serverKey = '';
        }
    },
    actions: {
        getServerKey({commit, state}) {
            return new Promise((resolve, reject) => {
                Axios.get('/auth/safety/getPublicKey').then(response => {
                    if (response.data.success) {
                        if (response.data.data) {
                            commit('set_serverKey', response.data.data);
                            resolve(response.data.data);
                        }
                    } else {
                        // this.$message({type:'warning',message:response.data.message})
                    }
                })
            })
        },

    }
};

const activeRouteModule = {
    state: {activeRoute: ''},
    mutations: {
        set_activeRoute(state, activeRoute) {
            state.activeRoute = activeRoute;
        },
    },
}

const themeColor = {
    state: {
        color: '#6AAF20',
        currentColor: '#FF7F50',
        voltageColor: '#63A6C5',
        tempColor: '#F18565',
        avgColor: '#63A6C5',
        maxTempColor: '#F18565',
        minTempColor: '#64AFAC',
        menuColor: "#6AAF20",
        titleColor: "#228B22",
        tableColor: "#DAE8CA",
        fontColor: "#2C3E50",
        inputBgColor: 'rgb(255,255,252)',
        bgColor: 'rgb(242,242,242)',
        bgColor1: 'rgb(106,175,32, 0.3)',
        bgColor2: 'rgb(106,175,32, 0.6)',
    },
    mutations: {
        setThemeColer(state) {
            let theme = localStorage.getItem("theme");
            if (theme) {
                let item = JSON.parse(theme);
                state.color = item.color;
                state.currentColor = item.currentColor;
                state.voltageColor = item.voltageColor;
                state.tempColor = item.tempColor;
                state.avgColor = item.avgColor;
                state.maxTempColor = item.maxTempColor;
                state.minTempColor = item.minTempColor;
                state.menuColor = item.menuColor;
                state.titleColor = item.titleColor;
                state.tableColor = item.tableColor;
                state.fontColor = item.fontColor;
                state.inputBgColor = item.inputBgColor;
                state.bgColor = item.bgColor;
                state.bgColor1 = item.bgColor1;
                state.bgColor2 = item.bgColor2;
            }
        },
    },
    actions: {
        setThemeColer: function (context) {
            context.commit('setThemeColer');
        }
    }
};

export default new Vuex.Store({
    modules: {
        token: tokenModule,
        user: userModule,
        requestCancel: requestCancelModule,
        ws: websocketModule,
        keys: keysModule,
        activeRoute: activeRouteModule,
        unread: unreadModule,
        systemImage: systemImageModule,
        themeColor: themeColor,
    }
});


