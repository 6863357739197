//定义路由
export default [
    {
        path: '/info',
        name: 'info',
        component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/Info.vue'),
        redirect: "/info/powerInfo",
        meta: {requireAuth: true, roles: ['U', 'I'],},
        children: [
            {
                path: '/info/powerInfo',
                name: 'powerData',
                meta: {requireAuth: true, roles: ['U', 'I']},
                component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/powerInfoMain.vue'),
                redirect: '/info/powerInfo/powerData',
                children: [
                    {
                        path: "/info/powerInfo/powerData",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/powerData.vue'),
                    },
                    {
                        path: "/info/powerInfo/details",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/details.vue'),
                    },
                    /*{
                        path: "/info/powerInfo/layout2D",
                        meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                        component: () => import(/!* webpackChunkName: "info" *!/ '@/views/powerInfo/layout2D.vue'),
                    },*/
                    {
                        path: "/info/powerInfo/layoutNew2D",
                        meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                        component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/layoutNew2D.vue'),
                    },
                    {
                        path: "/info/powerInfo/layoutStatic",
                        meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                        component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/layoutStatic.vue'),
                    },
                    {
                        path: '/info/powerInfo/layout3D',
                        meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                        component: () => import(/* webpackChunkName: "plantLocation" */ '@/views/powerInfo/layout3D.vue')
                    },
                ]
            },
            {
                path: "/info/setting",
                meta: {requireAuth: true, roles: ['U', 'I']},
                component: () => import(/* webpackChunkName: "setting" */ '@/views/setting/setting.vue'),
                redirect: "/info/setting/userInfo",
                children: [
                    {
                        path: "/info/setting/userInfo",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-user" */ '@/views/setting/user/userSetting.vue')
                    },
                    {
                        path: "/info/setting/accountSecurity",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-user" */ '@/views/setting/user/accountSecurity.vue')
                    },
                    {
                        path: "/info/setting/vip",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-user" */ '@/views/setting/user/vip.vue')
                    },
                    {
                        path: "/info/setting/billList",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-user" */ '@/views/setting/user/billList.vue')
                    },
                    {
                        path: "/info/setting/payment",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-user" */ '@/views/setting/user/payment.vue')
                    },
                    {
                        path: "/info/setting/profit",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-user" */ '@/views/setting/user/profit.vue')
                    },
                    {
                        path: "/info/setting/module",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/module.vue'),
                        redirect: '/info/setting/module/info',
                        children: [
                            {
                                path: "/info/setting/module/info",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/moduleDetails.vue'),
                            },
                            {
                                path: "/info/setting/module/share",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/sharingList.vue'),
                            },
                            {
                                path: "/info/setting/module/monitorChange",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/monitorsChangedList.vue'),
                            },
                        ]
                    },
                    {
                        path: "/info/setting/module/status",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/moduleStatus/moduleStatus.vue'),
                        redirect: '/info/setting/module/status/log',
                        children: [
                            {
                                path: "/info/setting/module/status/log",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/moduleStatus/moduleLog.vue'),
                                props: (route) => ({moduleId: route.query.moduleId}),
                            },
                            {
                                path: "/info/setting/module/status/state",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-module" */ '@/views/setting/module/moduleStatus/moduleWorkingState.vue'),
                                props: (route) => ({moduleId: route.query.moduleId}),
                            },
                        ]
                    },
                    {
                        path: "/info/setting/plant",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/plant/plantSetting.vue'),
                        redirect: '/info/setting/plant/list',
                        children: [
                            {
                                path: "/info/setting/plant/list",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/plant/plantList.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                            {
                                path: "/info/setting/plant/images",
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/images.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber, flag: 'plant4user'}),
                            }
                        ]
                    },
                    {
                        path: "/info/setting/communication/communicationSetting",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/communication/communicationSetting.vue'),
                    },
                    {
                        path: "/info/setting/communication/communicationMeshSetting",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/communication/communicationMeshSetting.vue'),
                    },
                    {
                        path: "/info/setting/plant/status",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/plant/plantStatus/plantStatus.vue'),
                        redirect: '/info/setting/plant/status/log',
                        children: [
                            {
                                path: '/info/setting/plant/status/log',
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/plant/plantStatus/plantLog.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                            {
                                path: '/info/setting/plant/status/state',
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-plant" */ '@/views/setting/plant/plantStatus/plantWorkingState.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                        ]
                    },
                    {
                        path: "/info/setting/normalQuestion",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-question" */ '@/views/setting/normalQuestion/normalQuestion.vue'),
                    },
                    {
                        path: "/info/setting/message",
                        meta: {requireAuth: true, roles: ['U', 'I']},
                        component: () => import(/* webpackChunkName: "setting-message" */ '@/views/setting/message/message.vue'),
                        redirect: '/info/setting/message/details',
                        children: [
                            {
                                path: '/info/setting/message/setting',
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-message" */ '@/views/setting/message/messageSetting.vue'),
                            },
                            {
                                path: '/info/setting/message/details',
                                meta: {requireAuth: true, roles: ['U', 'I']},
                                component: () => import(/* webpackChunkName: "setting-message" */ '@/views/setting/message/messageDetail.vue'),
                                props: (route) => ({Types: route.query.Types, moduleId: route.query.moduleId}),
                            },
                        ]
                    },

                ]
            },
            {
                path: '/info/plantLocation',
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/* webpackChunkName: "plantLocation" */ '@/views/plantLocation/plantLocation.vue')
            },
            {
                path: '/info/plantNewLocation',
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/* webpackChunkName: "plantLocation" */ '@/views/plantLocation/plantNewLocation.vue')
            },
            /*{
                path: "/info/setting/fullLayout",
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/!* webpackChunkName: "info" *!/ '@/views/powerInfo/fullLayout.vue'),
            },*/
            {
                path: "/info/setting/fullLayout2D",
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/fullLayout2D.vue'),
            },
            {
                path: "/info/setting/fullLayout3D",
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/* webpackChunkName: "info" */ '@/views/powerInfo/fullLayout3D.vue'),
            },
            {
                path: '/info/setting/setLayout',
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/* webpackChunkName: "plantLocation" */ '@/views/powerInfo/setLayout.vue'),
            },
            {
                path: '/info/setting/setLayout3D',
                meta: {requireAuth: true, roles: ['U', 'I', 'S', 'A']},
                component: () => import(/* webpackChunkName: "plantLocation" */ '@/views/powerInfo/setLayout3D.vue'),
            },
        ]
    }
]




